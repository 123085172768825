.container {
    display: flex;
    width: 60%;
    max-width: 1000px;
    background-color: #292929;
    align-items: stretch;
}

.sidebar {
    width: 25%;
}

.banner {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.banner {
    height: auto;
    max-height: 400px;
}


