@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

.sidebar {
    display: flex;
    flex-direction: column;
}

.profilePic {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 150px;
    height: 150px;
    border: 1px solid black;
    border-radius: 128px;
    margin: 8px 0 30px 0;
}

.links {
    display: flex;
    flex-direction: column;
    color: #84e84f;
    background-color: transparent;
    padding: 12px;
    list-style: none;
}

.links li {
    font-size: 1.5em;
    font-family: 'Courier Prime', 'Courier New', serif;
    padding: 12px 0 12px 8px;
    transition: 0.5s;
}

.links li:hover {
    background-color: #444;
}

.socials {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    color: #fff;
    align-items: center;
}

.socials img, object {
    padding: 0 8px;
    width: 24px;
    height: 24px;
}
