@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

.pages {
    margin: 8px;
    padding: 8px;
    color: #fff;
}

.title {
    display: flex;
    align-items: flex-start;
}

h1, .block {
    font-family: 'Courier Prime', 'Courier New', serif;
    color: #84e84f;
    font-weight: bold;
    margin: 0 8px 0 0;
}

h1 {
    font-size: 32px;
}

.block {
    font-size: 26px;
    animation: blinker 1.4s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.pages .eggs {
    background-color: transparent;
}